var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-bg"},[_c('Header',{attrs:{"percent":_vm.headerData.totalViewingPercent,"badgeData":_vm.headerData.categoryStatuses}}),_c('div',{staticClass:"banner"},[_c('swiper',{ref:"swiperId",attrs:{"options":_vm.sliderOptions},on:{"ready":function($event){return _vm.pushDLEvent()},"slideChange":_vm.changeSwiperIndex}},[_vm._l((_vm.academyData.banners),function(banner,index){return _c('swiper-slide',{key:index},[(banner.url)?_c('BrandVimeoPlayer',{ref:"vimeoPlayer",refInFor:true,attrs:{"id":("" + (banner.url)),"data":{
            embedVideo: ("" + (banner.url)),
          }}}):(_vm.getBannerImageUrl)?_c('BrandMediaContentBox',{attrs:{"mediaSrc":_vm.getBannerImageUrl,"aspectRatios":_vm.media.aspect.by_16_9}}):_vm._e()],1)}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"})],2)],1),_c('div',{staticClass:"section"},[(_vm.academyData.banners && _vm.academyData.banners[this.activeSlideIndex].description)?_c('VueText',{staticClass:"description",attrs:{"sizeLevel":"6","weightLevel":"3"}},[_vm._v(" "+_vm._s(_vm.academyData.banners[this.activeSlideIndex].description)+" ")]):_vm._e(),_c('VueHeadline',{staticClass:"headline",attrs:{"level":"3"}},[_vm._v("Uzmanlık Videoları")]),_c('div',{staticClass:"section-card-wrapper"},_vm._l((_vm.academyData.categories),function(category){return _c('router-link',{key:category.id,attrs:{"to":{
          name: 'Learn',
          params: { id: category.id, name: _vm.slugify(category.name) },
        }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-inner"},[_c('img',{class:['card-img', category.completedStep > 0 && 'active'],attrs:{"src":category.completedStep !== category.totalStep
                  ? category.inCompletedBackgroundUrl
                  : category.completedBackgroundUrl,"alt":category.name}}),(category.completedStep !== category.totalStep)?_c('div',{class:['card-step-bar', category.completedStep > 0 && 'active']},[_c('div',{staticClass:"step-bar"},_vm._l((category.totalStep),function(index){return _c('div',{key:index,class:[{ active: category.completedStep >= index }, 'step']})}),0),_c('span',{staticClass:"step-number"},[_vm._v(_vm._s(category.completedStep + '/' + category.totalStep))])]):_vm._e()])])])}),1)],1),_c('div',{staticClass:"link-btns"},[(_vm.customerDetail && _vm.customerDetail.isEOrderCustomer)?_c('BrandButton',{staticClass:"link-btn order",attrs:{"id":"link_btn_order"},on:{"click":_vm.openEOrder}},[_vm._v("HEMEN ÖN SİPARİŞ VER")]):_vm._e(),_c('BrandButton',{staticClass:"link-btn faq",attrs:{"as":"router-link","target":'/secure/tutun-akademi/sss'}},[_vm._v("SIKÇA SORULAN SORULAR")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }