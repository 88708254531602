<template>
  <div class="wrapper-bg">
    <Header :percent="headerData.totalViewingPercent" :badgeData="headerData.categoryStatuses" />
    <div class="banner">
      <swiper
        :options="sliderOptions"
        ref="swiperId"
        @ready="pushDLEvent()"
        @slideChange="changeSwiperIndex"
      >
        <swiper-slide :key="index" v-for="(banner, index) in academyData.banners">
          <BrandVimeoPlayer
            v-if="banner.url"
            :id="`${banner.url}`"
            ref="vimeoPlayer"
            :data="{
              embedVideo: `${banner.url}`,
            }"
          >
          </BrandVimeoPlayer>
          <BrandMediaContentBox
            v-else-if="getBannerImageUrl"
            :mediaSrc="getBannerImageUrl"
            :aspectRatios="media.aspect.by_16_9"
          >
          </BrandMediaContentBox>
        </swiper-slide>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
      </swiper>
    </div>
    <div class="section">
      <VueText
        v-if="academyData.banners && academyData.banners[this.activeSlideIndex].description"
        sizeLevel="6"
        weightLevel="3"
        class="description"
      >
        {{ academyData.banners[this.activeSlideIndex].description }}
      </VueText>
      <VueHeadline level="3" class="headline">Uzmanlık Videoları</VueHeadline>
      <div class="section-card-wrapper">
        <router-link
          :key="category.id"
          :to="{
            name: 'Learn',
            params: { id: category.id, name: slugify(category.name) },
          }"
          v-for="category in academyData.categories"
        >
          <div class="card">
            <div class="card-inner">
              <img
                :class="['card-img', category.completedStep > 0 && 'active']"
                :src="
                  category.completedStep !== category.totalStep
                    ? category.inCompletedBackgroundUrl
                    : category.completedBackgroundUrl
                "
                :alt="category.name"
              />
              <div
                :class="['card-step-bar', category.completedStep > 0 && 'active']"
                v-if="category.completedStep !== category.totalStep"
              >
                <div class="step-bar">
                  <div
                    v-for="index in category.totalStep"
                    :key="index"
                    :class="[{ active: category.completedStep >= index }, 'step']"
                  ></div>
                </div>
                <span class="step-number">{{
                  category.completedStep + '/' + category.totalStep
                }}</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="link-btns">
      <BrandButton
        class="link-btn order"
        id="link_btn_order"
        v-if="customerDetail && customerDetail.isEOrderCustomer"
        @click="openEOrder"
        >HEMEN ÖN SİPARİŞ VER</BrandButton
      >
      <BrandButton class="link-btn faq" as="router-link" :target="'/secure/tutun-akademi/sss'"
        >SIKÇA SORULAN SORULAR</BrandButton
      >
    </div>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueText from '@/components/shared/VueText/VueText.vue';
import Header from '@/components/brand/Headers/OtpHeader.vue';
import { Otp, Common } from '@/services/Api/index';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import { CONTENT_URL } from '@/constants/urls.constants.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import StorageProps from '@/mixins/storageProps';

export default {
  name: 'Otp',
  components: {
    VueHeadline,
    BrandButton,
    VueText,
    Header,
    BrandVimeoPlayer,
    swiper,
    swiperSlide,
    BrandMediaContentBox,
  },
  mixins: [gtmUtils, StorageProps],
  data() {
    return {
      headerData: {},
      academyData: {},
      sliderOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        resistance: true,
        resistanceRatio: 0.7,
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      activeSlideIndex: 0,
    };
  },
  created() {
    Otp.getHeaderData({}).then(res => {
      if (res.data && res.data.Data.header) {
        this.headerData = res.data.Data.header;
        this.$store.dispatch('app/setOtpCategoryStatuses', this.headerData.categoryStatuses);
        if (window.insider_object?.user?.custom)
          window.insider_object.user.custom.categoryStatuses = this.headerData.categoryStatuses;
      }
    });
    Otp.getAcademyData({}).then(res => {
      if (res.data && res.data.Data && res.data.Data.academy) {
        this.academyData = res.data.Data.academy;
      }
    });
  },
  computed: {
    calcDasharray() {
      let percent = 65;
      return (percent * 58) / 100 + ' ' + (75 - (percent * 58) / 100);
    },
    media() {
      return {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
      };
    },
    getBannerImageUrl() {
      return `${CONTENT_URL}/Files/Slider/Tütün_Ak._Hediye_Banner_App.jpg`;
    },
  },
  methods: {
    changeSwiperIndex() {
      this.activeSlideIndex = this.$refs.swiperId.swiper.activeIndex;
    },
    pushDLEvent() {
      if (this.academyData.banners?.length) {
        let sw = this.$refs['swiperId'];
        let index = sw.swiper.activeIndex;
        this.pushDataLayerEvent('slide', this.academyData.banners[index]);
      }
    },
    slugify(text) {
      var trMap = {
        çÇ: 'c',
        ğĞ: 'g',
        şŞ: 's',
        üÜ: 'u',
        ıİ: 'i',
        öÖ: 'o',
      };
      for (var key in trMap) {
        text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
      }
      return text
        .replace(/[^-a-zA-Z0-9\s]+/gi, '') // remove non-alphanumeric chars
        .replace(/\s/gi, '-') // convert spaces to dashes
        .replace(/[-]+/gi, '-') // trim repeated dashes
        .toLowerCase();
    },
    openEOrder() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('on_siparis_click', {
        location: 'tütün akademi',
      });
      Common.getEcommerceUrl().then(res => {
        const {
          Data: { url },
        } = res.data;
        if (url) {
          window.location = url;
        }
      });
    },
    otpCrush() {
      this.$router.push({
        path: '/secure/play-and-win/detail',
        query: { iframeGame: 1, title: 'Kıran Kırana' },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-button-prev,
.swiper-button-next {
  height: 30px;
  margin-top: -15px;
}
@font-face {
  font-family: Politica;
  src: local(Politica-Bold), url('~@/assets/font/politica-bold.woff') format('woff');
}
.wrapper-bg {
  background-image: url('~@/assets/otp/bg/otp_bg.png');
  background-position: bottom right;
  background-size: cover;
}
.headline {
  margin-top: 20px;
  font-weight: 500;
}
.section {
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  .section-card-wrapper {
    margin-top: 20px;

    .card {
      background-color: #fff;
      border: 1px solid #b4c2d3;
      margin-bottom: 20px;

      .card-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }
}
.description {
  border-bottom: 1px solid #b4c2d3;
  line-height: 1.22;
  padding-bottom: 18px;
}
.link-btns {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 60px;

  @media screen and (min-width: 415px) {
    max-width: 410px;
    margin: 0 auto;
  }
  .link-btn {
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.33;
    background-size: cover;
    height: 60px !important;
    justify-content: flex-start;
    padding-left: 75px;
    padding-right: 10px;
    @media screen and (max-width: 360px) {
      font-size: 16px;
      padding-left: 68px;
    }
    /deep/ span {
      font-family: Politica !important;
      letter-spacing: 0.7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
    }
    &.order {
      background-image: url('~@/assets/otp/bg/e-order-bg.png');
      &:before {
        content: url('~@/assets/otp/bg/e-order.png');
      }
    }
    &.contact {
      background-image: url('~@/assets/otp/bg/oneri-bg.png');
      &:before {
        content: url('~@/assets/otp/bg/oneri.png');
      }
    }
    &.faq {
      background-image: url('~@/assets/otp/bg/question-bg.png');
      &:before {
        content: url('~@/assets/otp/bg/question.png');
      }
    }
    &.game {
      background-image: url('~@/assets/otp/bg/game-bg.png');
      &:before {
        content: url('~@/assets/otp/bg/game.png');
      }
    }
  }
}

.card-img {
  width: 100%;
  filter: grayscale(1);
  &.active {
    filter: grayscale(0);
  }
}
.card-step-bar {
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f6f5ee;
  &.active {
    background-color: #fff;
    .step {
      &:after {
        background-color: #fff;
      }
    }
    .step-number {
      color: #c60000;
    }
  }
}
.step-bar {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  .step {
    height: 3px;
    background-color: #ebebeb;
    position: relative;
    flex: 1;
    &.active {
      background-color: #c60000;
      &:after {
        border-color: #c60000;
        background-color: #c60000;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 14px;
      height: 14px;
      border: 2px solid #ebebeb;
      border-radius: 7px;
      background-color: #f6f5ee;
      transform: translateY(-46%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: #ebebeb;
    }
    &:last-child {
      &:after {
        content: 'Quiz';
        width: 40px;
        right: -20px;
        padding: 8px;
        border-radius: 9px;
      }
    }
  }
}
.step-number {
  font-size: 14px;
  font-weight: bold;
  color: #5a5951;
  margin-left: 35px;
}
a {
  text-decoration: none;
}
</style>
